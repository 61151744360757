import authService from 'services/auth';
import {data} from 'configs/menu-config';
import {setMenu} from 'custom-redux/slices/menu';
import {useTranslation} from 'react-i18next';
import Recaptcha from 'components/recaptcha';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setUserData, updateUser} from 'custom-redux/slices/auth';
import {LockOutlined, MailOutlined} from '@ant-design/icons';
import serviceMasterService from 'services/master/serviceMaster';
import {fetchRestSettings, fetchSettings} from 'custom-redux/slices/globalSettings';
import {Button, Card, Col, Descriptions, Form, Image, Input, notification, Row} from 'antd';
//
// const credentials = [
//     {
//         login: 'owner@githubit.com',
//         password: 'githubit',
//     },
//     {
//         login: 'manager@githubit.com',
//         password: 'manager',
//     },
//     {
//         login: 'sellers@githubit.com',
//         password: 'seller',
//     },
//     {
//         login: 'moderator@githubit.com',
//         password: 'moderator',
//     },
//     {
//         login: 'delivery@githubit.com',
//         password: 'delivery',
//     },
//     {
//         login: 'master@githubit.com',
//         password: 'master',
//     },
// ];

const Login = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const {settings} = useSelector((state) => state.globalSettings);
    const [recaptcha, setRecaptcha] = useState(null);
    const isDemo = Boolean(Number(settings?.is_demo));
    const recaptchaRef = React.useRef();
    const onSubmitWithReCAPTCHA = async () => {
        const token = await recaptchaRef.current.executeAsync();
        setRecaptcha(token);
    }

    const handleLogin = async (values) => {
        await onSubmitWithReCAPTCHA();

        const body = {
            password: values.password,
        };

        if (values.email.includes('@')) {
            body.email = values.email;
        } else {
            body.phone = values.email.replace(/[^0-9]/g, '');
        }

        setLoading(true);

        authService
            .login(body)
            .then((res) => {
                const user = {
                    fullName: res.data.user.firstname + ' ' + res.data.user.lastname,
                    role: res.data.user.role,
                    urls: data[res.data.user.role],
                    img: res.data.user.img,
                    token: res.data.access_token,
                    email: res.data.user.email,
                    id: res.data.user.id,
                    shop_id: res.data.user?.shop?.id,
                    walletId: res.data?.user?.wallet?.id,
                };

                if (user.role === 'waiter') {
                    dispatch(
                        setMenu({
                            icon: 'user',
                            id: 'orders-board',
                            name: 'my.orders',
                            url: 'waiter/orders-board',
                        }),
                    );
                }

                if (user.role === 'user') {
                    notification.error({message: t('ERROR_101')});

                    return;
                }

                localStorage.setItem('token', res.data.access_token);

                dispatch(setUserData(user));

                if (user.role === 'master') {
                    serviceMasterService
                        .show(user.id)
                        .then((res) => {
                            dispatch(updateUser(res.data));
                        })
                        .catch((err) => {
                            throw err;
                        });
                }

                if (user.role === 'admin') {
                    dispatch(fetchSettings());
                } else {
                    dispatch(fetchRestSettings());
                }
            })
            .finally(() => setLoading(false));
    };

    // const copyCredentials = (event, item) => {
    //     event.preventDefault();
    //     form.setFieldsValue({email: item.login, password: item.password});
    // };

    useEffect(() => {
        dispatch(fetchRestSettings());
    }, []);

    return (
            <div className="login-container">
                <div className="container d-flex flex-column justify-content-center h-100 align-items-center">
                    <Row justify="center">
                        <Col>
                            <Card style={{ backgroundColor: '#283142', border: 0 }} className="card">
                                <div className="app-brand text-center">
                                    <Image
                                        width={220}
                                        height={55}
                                        preview={false}
                                        src={'/coify-logo-white.png'}
                                    />
                                </div>

                                <div className="my-4 pl-4 pr-4 w-100 d-flex flex-column align-items-center">
                                    <Row justify="center">
                                        <Col>
                                            <Form
                                                form={form}
                                                name={'login-form'}
                                                layout={'vertical'}
                                                onFinish={handleLogin}
                                                className="login-form d-flex flex-column align-items-center"
                                            >
                                                <Form.Item
                                                    name={'email'}
                                                    style={{ width: '100%' }}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please enter your email!',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        placeholder={'Email or phone'}
                                                        prefix={<MailOutlined className="site-form-item-icon" />}
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    name={'password'}
                                                    style={{ width: '100%' }}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please enter your password!',
                                                        },
                                                    ]}
                                                >
                                                    <Input.Password
                                                        placeholder={'Password'}
                                                        prefix={<LockOutlined className="site-form-item-icon" />}
                                                    />
                                                </Form.Item>

                                                <Recaptcha ref={recaptchaRef} />

                                                <Form.Item className="login-input mt-4" style={{ width: '100%', textAlign: 'center' }}>
                                                    <Button
                                                        type={'primary'}
                                                        loading={loading}
                                                        htmlType={'submit'}
                                                        className={'login-form-button'}
                                                    >
                                                        {t('Login')}
                                                    </Button>
                                                </Form.Item>
                                            {/*{*/}
                                            {/*    isDemo &&*/}
                                            {/*    <Descriptions bordered size='small'>*/}
                                            {/*        {*/}
                                            {/*            credentials.map((item, idx) => (*/}
                                            {/*                <React.Fragment key={idx}>*/}
                                            {/*                    <Descriptions.Item span={2} label={item.login}>*/}
                                            {/*                        {item.password}*/}
                                            {/*                    </Descriptions.Item>*/}

                                            {/*                    <Descriptions.Item span={1}>*/}
                                            {/*                        <a*/}
                                            {/*                            href={'/'}*/}
                                            {/*                            className={'copy-link'}*/}
                                            {/*                            onClick={(event) =>*/}
                                            {/*                                copyCredentials(event, item)*/}
                                            {/*                            }*/}
                                            {/*                        >*/}
                                            {/*                            {t('Copy')}*/}
                                            {/*                        </a>*/}
                                            {/*                    </Descriptions.Item>*/}
                                            {/*                </React.Fragment>*/}
                                            {/*            ))*/}
                                            {/*        }*/}
                                            {/*    </Descriptions>*/}
                                            {/*}*/}

                                            {/*{*/}
                                            {/*    !isDemo && process.env.REACT_APP_IS_DEMO === 'true' &&*/}
                                            {/*    <Descriptions size='small'>*/}
                                            {/*        <Descriptions.Item span={4} label='Login'>*/}
                                            {/*            {credentials[0].login}*/}
                                            {/*        </Descriptions.Item>*/}
                                            {/**/}
                                            {/*        <Descriptions.Item span={4} label='Password'>*/}
                                            {/*            {credentials[0].password}*/}
                                            {/*        </Descriptions.Item>*/}
                                            {/*    </Descriptions>*/}
                                            {/*}*/}
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
export default Login;
