import ReCAPTCHA from "react-google-recaptcha";
import {RECAPTCHA_SITE_KEY} from "configs/app-global";
import {forwardRef} from 'react';

const Recaptcha = forwardRef((props, ref) => {
    return (
        <ReCAPTCHA
            ref={ref}
            size='invisible'
            badge='bottomright'
            sitekey={RECAPTCHA_SITE_KEY}
        />
    );
});

export default Recaptcha;