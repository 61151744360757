import React from 'react';
import {ConfigProvider} from 'antd';
import i18n from './configs/i18next';
import AppLocale from './configs/app-locale';
import useBodyClass from './helpers/useBodyClass';
import {PROJECT_NAME} from './configs/app-global';
import useDocumentTitle from './helpers/projectTitle';
import {shallowEqual, useSelector} from 'react-redux';

export default function Providers({children}) {
    const {theme} = useSelector((state) => state.theme, shallowEqual);

    useBodyClass(`dir-${theme.direction}`);

    useDocumentTitle(PROJECT_NAME);

    return (
        <ConfigProvider direction={theme.direction} locale={AppLocale[i18n.language]}>
            {children}
        </ConfigProvider>
    );
}
