import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

export const ProtectedRoute = ({ children }) => {
  const { user } = useSelector((state) => state.auth, shallowEqual);

  console.log('ProtectedRoute -> user:', user);  // Add this for debugging

  if (!user) {
    console.log('User not authenticated, redirecting to /login');
    return <Navigate to='/login' replace />;
  }
  return children;
};
