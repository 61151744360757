export const PROJECT_NAME = 'Coify CMS';
export const BASE_URL = 'https://api.dev.coify.app';
// export const BASE_URL = 'http://localhost:8080';
export const WEBSITE_URL = 'https://admin.dev.coify.app';
// export const WEBSITE_URL = 'http://localhost:8080';
export const api_url = BASE_URL + '/api/v1/';
export const api_url_admin = BASE_URL + '/api/v1/dashboard/admin/';
export const api_url_admin_dashboard = BASE_URL + '/api/v1/dashboard/';
export const IMG_URL = '';
export const MAP_API_KEY = 'AIzaSyD5-9xGlKiNIuk8WaKHRDWM7KTJSiiyowI';
export const export_url = BASE_URL + '/';
export const example = BASE_URL + '/';
export const defaultCenter = { lat: 40.7127281, lng: -74.0060152 };

export const VAPID_KEY =
  'BEYQkwcPyieTg5gude4ClBktW_tIEnpSEFo32Q_0xGAUv4uBvWkvmJqkb2HMFd8ERnf00EsuTtP0JMqutAdrgwc';
export const API_KEY = 'AIzaSyDXlndw2JaH6EH170arg0QbEgpMDpaWHNQ';
export const AUTH_DOMAIN = 'coify-408022.firebaseapp.com';
export const PROJECT_ID = 'coify-408022';
export const STORAGE_BUCKET = 'coify-408022.appspot.com';
export const MESSAGING_SENDER_ID = '313429030044';
export const APP_ID = '1:313429030044:web:e55a410354b9efdc926959';
export const MEASUREMENT_ID = 'G-XZJSYKV3BY';
export const DYNAMIC_LINK_DOMAIN = 'https://ibeautyapp.page.link';
export const ANDROID_PACKAGE_NAME = 'org.uzmart';
export const IOS_BUNDLE_ID = 'com.gshop';

export const RECAPTCHA_SITE_KEY = '6LdI_jsqAAAAAIdFfQJs0Hbamd-zThqd7Rt3TwR3';

export const DEMO_SELLER = 107; // seller_id
export const DEMO_SELLER_UUID = '3566bdf6-3a09-4488-8269-70a19f871bd0'; // seller_id
export const DEMO_SHOP = 501; // seller_id
export const DEMO_DELIVERYMAN = 106; // deliveryman_id
export const DEMO_MANAGER = 114; // manager_id
export const DEMO_MODERATOR = 297; // moderator_id
export const DEMO_ADMIN = 501; // administrator_id
export const DEMO_WAITER = 108; // waiter_id
export const DISPLAY_HIDDEN_ITEMS = false; // used to hide items from the system

export const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/svg',
];
