import React, {useState} from 'react';
import {clearMenu} from 'custom-redux/slices/menu';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {setParcelMode} from 'custom-redux/slices/theme';
import {Button, Col, Modal, Row, Switch} from 'antd';
import {DISPLAY_HIDDEN_ITEMS} from "../../configs/app-global";
import {shallowEqual, useDispatch, useSelector} from 'react-redux';

export default function ParcelFloat() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const {theme} = useSelector((state) => state.theme, shallowEqual);
    const {settings} = useSelector((state) => state.globalSettings, shallowEqual);

    const activeParcel = Number(settings?.active_parcel);

    const handleChange = (event) => {
        if (activeParcel === 0) {
            setShowModal(true);
        } else {
            dispatch(setParcelMode(event));
            dispatch(clearMenu());
            navigate('/');
        }
    };

    return (
        <>
        {
            DISPLAY_HIDDEN_ITEMS &&
            <div className='parcel-float'>
                <div
                    className='d-flex align-items-center justify-content-between'
                    style={{columnGap: 12}}
                >
                    <label>{t('parcel.mode')}:</label>
                    <Switch checked={theme.parcelMode} onChange={handleChange}/>
                </div>
            </div>
        }

            <Modal
                visible={showModal}
                onCancel={() => setShowModal(false)}
                footer={[
                    <Button type='default' onClick={() => setShowModal(false)}>
                        {t('cancel')}
                    </Button>,
                ]}
            >
                <Row gutter={12}>
                    <Col span={24}>
                        <p>{t('first.activate.the.parcel')}</p>
                        <p>{t('path.business.settings.general.settings.permission')}</p>
                    </Col>
                </Row>
            </Modal>
        </>
    );
}
