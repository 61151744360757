import Login from 'views/login';
import {AllRoutes} from 'routes';
import Providers from 'providers';
import i18n from 'configs/i18next';
import NotFound from 'views/not-found';
import Loading from 'components/loading';
import AppLayout from 'layout/app-layout';
import {ToastContainer} from 'react-toastify';
import {PathLogout} from 'context/path-logout';
import PageLoading from 'components/pageLoading';
import {Suspense, useEffect, useState} from 'react';
import {ProtectedRoute} from 'context/protected-route';
import informationService from 'services/rest/information';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';

const App = () => {
    const [loading, setLoading] = useState(false);

    function fetchTranslations() {
        const params = {lang: i18n.language};

        setLoading(true);

        informationService
            .translations(params)
            .then(({data}) =>
                i18n.addResourceBundle(i18n.language, 'translation', data),
            )
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        fetchTranslations();
    }, []);

    return (
        <Providers>
            <Router>
                <Routes>
                    <Route
                        index
                        path='/login'
                        element={
                            <PathLogout>
                                <Login/>
                            </PathLogout>
                        }
                    />

                    <Route
                        path=''
                        element={
                            <ProtectedRoute>
                                <AppLayout/>
                            </ProtectedRoute>
                        }
                    >
                        <Route path='/' element={<Navigate to='dashboard'/>}/>

                        {
                            AllRoutes.map(({path, component: Component}) => (
                                <Route key={path} path={path} element={<Component/>}/>
                            ))
                        }
                    </Route>

                    <Route
                        path='*'
                        element={
                            <Suspense fallback={<Loading/>}>
                                <NotFound/>
                            </Suspense>
                        }
                    />
                </Routes>

                <ToastContainer
                    className='antd-toast'
                    position='top-right'
                    autoClose={2500}
                    hideProgressBar
                    closeOnClick
                    pauseOnHover
                    draggable
                />
                {loading && <PageLoading/>}
            </Router>
        </Providers>
    );
};
export default App;
